import "../bootstrap";
import "../tinyslider";

document.querySelectorAll(".established").forEach((element) => {
    element.textContent = `${new Date().getFullYear() - 2014}+`;
});

const setEqualHeights = (selector) => {
    let timeout;

    const adjustHeights = () => {
        const items = document.querySelectorAll(selector);
        if (!items.length) return;

        let maxHeight = 0;
        items.forEach(item => {
            item.style.height = 'auto';
            maxHeight = Math.max(maxHeight, item.offsetHeight);
        });

        items.forEach(item => (item.style.height = `${maxHeight}px`));
    };

    const debouncedAdjustHeights = () => {
        clearTimeout(timeout);

        timeout = setTimeout(() => adjustHeights(), 50);
    };

    const observer = new ResizeObserver(debouncedAdjustHeights);
    const container = document.querySelector(selector)?.parentElement || document.body;
    observer.observe(container);

    adjustHeights();
};

window.setEqualHeights = setEqualHeights;

function initializeSlider(containerSelector, options) {
    const container = document.querySelector(containerSelector);

    if (container && container.children.length > 0) tns(options);
}

initializeSlider(
    ".item-slider",
    {
        container: ".item-slider",
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: true,
        gutter: 20,
        nav: false,
        controlsPosition: "bottom",
        controlsContainer: "#industry-control",
        responsive: {
            320: { items: 1 },
            430: { items: 1 },
            624: { items: 2 },
            768: { items: 1 },
            1024: { items: 1.5 },
            1080: { items: 1.5 },
            1280: { items: 2.2 },
            1440: { items: 2.5 },
            1660: { items: 2.8 },
        },
    }
);

initializeSlider(
    ".testimonial-slider",
    {
        container: ".testimonial-slider",
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: true,
        gutter: 16,
        nav: false,
        controlsPosition: "bottom",
        controlsContainer: "#testimonial-control",
        responsive: {
            320: { items: 1 },
            768: { items: 1 },
            1024: { items: 2 },
            1280: { items: 2 },
            1441: { items: 3 },
        },
    }
);

if (window.innerWidth >= 600) {
    initializeSlider(
        ".my-slider",
        {
            container: ".my-slider",
            autoplay: false,
            center: false,
            mouseDrag: true,
            loop: false,
            arrowKeys: true,
            nav: false,
            controlsPosition: "bottom",
            controlsContainer: "#custom-control",
            items: 2.5,
            responsive: {
                1440: { items: 2.5 },
            },
        }
    );

    // Add scroll navigation
    const sliderContainer = document.querySelector(".my-slider");
    if (sliderContainer) {
        sliderContainer.addEventListener("wheel", function (event) {
            if (event.deltaY < 0) slider.goTo("prev");

            else slider.goTo("next");
        });
    }
}

const tabPanes = document.querySelectorAll(".tab-pane");
tabPanes.forEach((pane) => {
    const sliderContainer = pane.querySelector(".ppc-slider");
    if (sliderContainer && sliderContainer.children.length > 0) {
        tns({
            container: sliderContainer,
            autoplay: false,
            center: false,
            mouseDrag: true,
            loop: false,
            arrowKeys: false,
            gutter: 20,
            nav: true,
            navPosition: "bottom",
            controls: false,
            responsive: {
                320: { items: 1 },
                740: { items: 2 },
                768: { items: 2 },
                1024: { items: 2 },
                1288: { items: 3 },
            },
        });
    }
});

