import "../bootstrap";
import "../tinyslider";
var sliderContainer = document.querySelector(".item-slider");
if (sliderContainer) {
    var slider = tns({
        container: sliderContainer,
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: true,
        gutter: 20,
        nav: false,
        controlsPosition: "bottom",
        controlsContainer: "#industry-control",
        responsive: {
            320: {
                items: 1,
            },
            430: {
                items: 1,
            },
            624: {
                items: 2,
            },
            768: {
                items: 1,
            },
            1024: {
                items: 1.5,
            },
            1080: {
                items: 1.5,
            },
            1280: {
                items: 2.2,
            },
            1440: {
                items: 2.5,
            },
            1660: {
                items: 2.8,
            },
        },
    });
}

var sliderTestimonials = document.querySelector(".testimonial-slider");
if (sliderTestimonials) {
    var slider = tns({
        container: sliderTestimonials,
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: true,
        gutter: 16,
        nav: false,
        controlsPosition: "bottom",
        controlsContainer: "#testimonial-control",
        responsive: {
            320: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1024: {
                items: 2,
            },
            1280: {
                items: 2,
            },
            1441: {
                items: 3,
            },
        },
    });

    function setEqualHeightVisible(entries) {
        let visibleItems = [];
        let maxHeight = 0;

        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                visibleItems.push(entry.target);
                maxHeight = Math.max(maxHeight, entry.target.offsetHeight);
            }
        });

        visibleItems.forEach((item) => {
            item.style.height = maxHeight + "px";
        });
    }

    const observer = new IntersectionObserver(setEqualHeightVisible, {
        root: sliderTestimonials,
        rootMargin: "0px",
        threshold: 0.1,
    });

    const testimonials = document.querySelectorAll(".testimonial-slider .tns-item");

    testimonials.forEach((item) => {
        observer.observe(item);
    });

    window.addEventListener("resize", () => {
        testimonials.forEach((item) => (item.style.height = "auto"));

        const newEntries = Array.from(testimonials).map((item) => ({
            isIntersecting: true,
            target: item,
        }));

        setEqualHeightVisible(newEntries);
    });
}

var sliderContainer = document.querySelector(".my-slider");

if (window.innerWidth >= 600 && sliderContainer) {
    var slider = tns({
        container: sliderContainer,
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: true,
        nav: false,
        controlsPosition: "bottom",
        controlsContainer: "#custom-control",
        items: 2.5,
        responsive: {
            1440: {
                items: 2.5,
            },
        },
    });
    sliderContainer.addEventListener("wheel", function (event) {
        if (event.deltaY < 0) {
            slider.goTo("prev");
        } else {
            slider.goTo("next");
        }
    });
}

var sliderContainers = document.querySelectorAll(".tab-pane");

sliderContainers.forEach(function (container) {
    var sliderContainer = container.querySelector(".ppc-slider");

    var slider = tns({
        container: sliderContainer,
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: false,
        gutter: 20,
        nav: true,
        navPosition: "bottom",
        controls: false,
        responsive: {
            320: {
                items: 1,
            },
            740: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1024: {
                items: 2,
            },
            1288: {
                items: 3,
            },
        },
    });
});

window.onload = function () {
    var dropdowns = document.querySelectorAll(".nav-item.dropdown");
    var currentTimeout = null;
    var hoveredDropdown = null;

    dropdowns.forEach(function (dropdown) {
        var dropdownMenu = dropdown.querySelector(".dropdown-menu");

        dropdown.addEventListener("mouseenter", function () {
            clearTimeout(currentTimeout);

            if (hoveredDropdown && hoveredDropdown !== dropdown) {
                currentTimeout = setTimeout(function () {
                    closeAllDropdowns();
                    showDropdownWithOverlay(dropdown, dropdownMenu);
                    dropdown.classList.add("hovered");
                    hoveredDropdown = dropdown;
                }, 300);
            } else {
                closeAllDropdowns();
                showDropdownWithOverlay(dropdown, dropdownMenu);
                dropdown.classList.add("hovered");
                hoveredDropdown = dropdown;
            }
        });

        dropdown.addEventListener("mouseleave", function () {
            clearTimeout(currentTimeout);
            currentTimeout = setTimeout(function () {
                hideDropdownWithOverlay(dropdown, dropdownMenu);
                dropdown.classList.remove("hovered");
                if (!isAnyDropdownHovered()) {
                    hoveredDropdown = null;
                }
            }, 300);
        });

        dropdownMenu.addEventListener("mouseenter", function () {
            clearTimeout(currentTimeout); // Cancel delay when entering the dropdown menu
        });

        dropdownMenu.addEventListener("mouseleave", function () {
            currentTimeout = setTimeout(function () {
                hideDropdownWithOverlay(dropdown, dropdownMenu);
                dropdown.classList.remove("hovered");
                if (!isAnyDropdownHovered()) {
                    hoveredDropdown = null;
                }
            }, 200);
        });

        dropdownMenu.addEventListener("click", function () {
            hideDropdownWithOverlay(dropdown, dropdownMenu);
        });

        var dropdownLinks = dropdownMenu.querySelectorAll("a");
        dropdownLinks.forEach(function (link) {
            link.addEventListener("click", function () {
                dropdown.querySelector(".drop-overlay").style.display = "none";
                dropdownMenu.style.display = "none";
            });
        });
    });

    function closeAllDropdowns() {
        dropdowns.forEach(function (dropdown) {
            hideDropdownWithOverlay(
                dropdown,
                dropdown.querySelector(".dropdown-menu")
            );
        });
    }

    function showDropdownWithOverlay(dropdown, dropdownMenu) {
        dropdownMenu.style.display = "block";
        let overlay = dropdown.querySelector(".drop-overlay");
        if (!overlay) {
            overlay = document.createElement("div");
            overlay.className = "drop-overlay";
            dropdown.appendChild(overlay);
            overlay.addEventListener("mouseenter", function () {
                dropdownMenu.style.display = "none";
                this.style.display = "none";
            });

            overlay.addEventListener("mouseleave", function () {
                setTimeout(function () {
                    hideDropdownWithOverlay(dropdown, dropdownMenu);
                }, 200);
            });
        }
        overlay.style.display = "block";
    }

    function hideDropdownWithOverlay(dropdown, dropdownMenu) {
        let overlay = dropdown.querySelector(".drop-overlay");
        if (
            !dropdown.matches(":hover") &&
            !dropdownMenu.matches(":hover") &&
            (!overlay || !overlay.matches(":hover"))
        ) {
            dropdownMenu.style.display = "none";
            if (overlay) {
                overlay.style.display = "none";
            }
        }
    }

    function isAnyDropdownHovered() {
        return Array.from(dropdowns).some(function (dropdown) {
            return dropdown.classList.contains("hovered");
        });
    }
};

document.querySelectorAll(".established").forEach((element) => {
    element.textContent = `${new Date().getFullYear() - 2014}+`;
});

const setEqualHeights = (selector) => {
    const adjustHeights = () => {
        const items = document.querySelectorAll(selector);
        if (!items.length) return;

        let maxHeight = 0;
        items.forEach(item => {
            item.style.height = 'auto';
            maxHeight = Math.max(maxHeight, item.offsetHeight);
        });

        items.forEach(item => (item.style.height = `${maxHeight}px`));
    };

    const observer = new ResizeObserver(adjustHeights);
    const container = document.querySelector(selector)?.parentElement || document.body;
    observer.observe(container);

    adjustHeights();
};

window.setEqualHeights = setEqualHeights;


